










































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			name: "",
			email: "",
			number: "",
			phone: "",
			content: "",
			isModification: false
		};
	},
	created() {
		if (this.getUserToken) {
			this.reload();
		}
		if (this.getUserInformation.name) {
			this.name = this.getUserInformation.name;
			this.email = this.getUserInformation.email;
		} else {
			this.$router.back();
		}
	},
	watch: {
		getUserToken() {
			if (this.getUserInformation.name) {
				this.name = this.getUserInformation.name;
			}
			if (this.getUserInformation.email) {
				this.email = this.getUserInformation.email;
			}
			this.reload();
		}
	},
	methods: {
		reload() {
			this.$store.commit("pushLoading", {
				name: "GET_MY_APPLICANT",
				message: "지원서 불러오는 중"
			});
			this.$store
				.dispatch("GET_MY_APPLICANT")
				.then(applicant => {
					this.isModification = true;
					this.name = applicant.name;
					this.email = applicant.email;
					this.number = applicant.number;
					this.phone = applicant.phone;
					this.content = applicant.content;
					this.$store.commit("clearLoading", "GET_MY_APPLICANT");
				})
				.catch(err => {
					this.$store.commit("clearLoading", "GET_MY_APPLICANT");
				});
		},
		save() {
			if (this.name.trim().length > 0 && this.email.trim().length > 0 && this.number.trim().length > 0 && this.phone.trim().length > 0 && this.content.trim().length > 0) {
				this.$store.commit("pushLoading", {
					name: "APPLICANT_MODIFICATION",
					message: "지원서 수정하는 중"
				});
				this.$store
					.dispatch("APPLICANT_MODIFICATION", {
						name: this.name,
						email: this.email,
						number: this.number,
						phone: this.phone,
						content: this.content
					})
					.then(data => {
						this.$store.commit("clearLoading", "APPLICANT_MODIFICATION");
						this.reload();
					})
					.catch(err => {
						this.$store.commit("clearLoading", "APPLICANT_MODIFICATION");
						console.log(err);
					});
			}
		},
		submit() {
			if (this.isModification) {
				this.$store.commit("pushLoading", {
					name: "APPLICANT_MODIFICATION",
					message: "지원서 수정하는 중"
				});
				this.$store
					.dispatch("APPLICANT_MODIFICATION", {
						name: this.name,
						email: this.email,
						number: this.number,
						phone: this.phone,
						content: this.content
					})
					.then(data => {
						this.$store.commit("clearLoading", "APPLICANT_MODIFICATION");
						this.$router.back();
					})
					.catch(err => {
						this.$store.commit("clearLoading", "APPLICANT_MODIFICATION");
						console.log(err);
					});
			} else {
				this.$store.commit("pushLoading", {
					name: "APPLICANT",
					message: "지원서 제출하는 중"
				});
				this.$store
					.dispatch("APPLICANT", {
						name: this.name,
						email: this.email,
						number: this.number,
						phone: this.phone,
						content: this.content
					})
					.then(data => {
						this.$store.commit("clearLoading", "APPLICANT");
						this.reload();
					})
					.catch(err => {
						this.$store.commit("clearLoading", "APPLICANT");
						console.log(err);
					});
			}
		}
	},
	computed: {
		getUserInformation() {
			return this.$store.state.userInformation;
		},
		getUserToken() {
			return this.$store.state.userToken;
		},
		getIsModificationText(): string {
			return this.isModification ? "수정" : "작성";
		}
	}
});
